import React from 'react'
import privacy from '../../images/privacy-policy.png'

export const Privacy: React.FC = () => {
  return (
    <div className='flex justify-center items-center my-8'>
      <img 
      src={privacy}/>
    </div>
  )
}