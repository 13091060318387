import React, { useEffect, useState } from 'react'

export const PoliticalPoll: React.FC = () => {
  const [harris, setHarris] = useState(0);
  const [trump, setTrump] = useState(0);
  const [totalVotes, setTotalVotes] = useState(10)
  const [voted, setVoted] = useState(false)
  const [hPercent, setHpercent] = useState(0)
  const [tPercent, setTpercent] = useState(0)

  const getRandomInt = (min, max) => {
    return Math.random() * (max - min) + min;
}

  const calculatePercentages = (votes1 = 8, votes2 = 2) => {
    setVoted(true)

    const num = getRandomInt(1, 8.8)
    
    const harrisPercent = (num / 10) * 100;
    const t = 10 - num
    const trumpPercent = (t / 10) * 100;

    setHpercent(harrisPercent);
    setTpercent(trumpPercent)
  };

  return (
    <div className='my-8 flex flex-col h-full w-full justify-center items-center text-5xl font-DM'>
        <p className='text-white'>Today&apos;s Poll:</p>
      <div className='mt-20 text-4xl flex flex-col gap-2 justify-center items-center text-white'>
        <p>Choose your fighter for the Presidential Debate!</p>
      </div>
      {!voted ? (
        <>
          <div className='mt-20 flex px-4 gap-8'>
            <div onClick={() => calculatePercentages(0, 1)} className='flex px-4 pb-12 bg-white rounded-lg flex-col items-center cursor-pointer bg-red border border-white '>
              <p className='mt-4 text-red-600'>Donald Trump</p>
              <img className='size-40 mt-4' src='https://images.unsplash.com/photo-1580128660010-fd027e1e587a?q=80&w=2864&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'/>
            </div>
            <div onClick={() => calculatePercentages(1, 0)} className='flex px-4 pb-6 bg-white rounded-lg flex-col items-center cursor-pointer bg-red border border-white '>
              <p className='mt-4 text-blue-600'>Kamala Harris</p>
              <img className='size-40 mt-4' src='https://st4.depositphotos.com/28355046/31055/i/450/depositphotos_310553008-stock-photo-san-francisco-august-2019-presidential.jpg'/>
            </div>
          </div>
            <p className='text-sm mt-8 text-white'>Select an option to view poll results.</p>
        </>
      ):( 
        <>
        <div className='mt-20 flex gap-8'>
            <div className='flex px-4 pb-12 bg-white rounded-lg flex-col items-center cursor-pointer bg-red border border-white '>
              <p className='mt-4 text-red-600'>Donald Trump</p>
              <img className='size-40 mt-4' src='https://images.unsplash.com/photo-1580128660010-fd027e1e587a?q=80&w=2864&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'/>
              <p className='mt-4 text-md'>{tPercent?.toFixed(1)}%</p>
            </div>
            <div className='flex px-4 pb-6 bg-white rounded-lg flex-col items-center cursor-pointer bg-red border border-white '>
              <p className='mt-4 text-blue-600'>Kamala Harris</p>
              <img className='size-40 mt-4' src='https://st4.depositphotos.com/28355046/31055/i/450/depositphotos_310553008-stock-photo-san-francisco-august-2019-presidential.jpg'/>
              <p className='mt-4 text-md'>{hPercent?.toFixed(1)}%</p>
            </div>
          </div>
          <p className='text-sm mt-8 text-white'>Thank you for your vote.</p>

        </>
      )}
    </div>
  )
}
