import React from 'react'
import { Link, Route } from 'react-router-dom';

export const Header: React.FC = () => {
  return (
    <>
      <div className='text-5xl font-DM text-white bg-black w-full flex flex-col items-center justify-center p-4'> 
        <p className=''>POLLOROO</p>
        <p className='text-lg'>The best source for insights</p>
      </div>
      <div className='w-full flex justify-center gap-4 bg-white p-2'>
        <Link to={'/'}>Home</Link>
        {/* <Link to={'/trending'}>Trending Polls</Link> */}
        <Link to={'/about'}>About Us</Link>
        <Link to={'/privacy'}>Privacy Policy</Link>
        {/* <Link to={'/contact'}>Contact</Link> */}
      </div>
    </>
  )
}
