import React from 'react'

export const About: React.FC = () => {
  return (
    <div className='mt-8 p-8 flex flex-col gap-8 text-white whitespace-pre-line'>
      <p className='bg-white rounded-lg p-4 text-black'>
        Welcome to Polloroo – the go-to platform for participating in quick, fun, and meaningful polls and surveys. We believe that everyone’s opinion matters, and we’re here to make sharing your voice easy and engaging. Whether you’re offering feedback on products, expressing your opinion on current events, or simply participating in social polls, Polloroo is your space to make your voice heard.
      </p>
      <p className='text-2xl text-center'>Our Mission</p>
      <p>
        Our mission at Polloroo is to connect people with opportunities to share their opinions in a simple, straightforward way. By providing a seamless platform for poll and survey participation, we help bridge the gap between individuals and the decisions that shape their world.
      </p>
      <p className='text-2xl text-center'>Why Polloroo?</p>
      <p>
        Polloroo is built for people who want to share their opinions quickly and easily. Our platform makes it enjoyable to contribute to surveys without the hassle. You can influence product development, participate in research, or just have fun answering social polls. With Polloroo, your input becomes part of a collective voice shaping decisions, products, and trends.
      </p>
      <p className='text-2xl text-center'>Join Polloroo Today</p>
      <p>
        Start participating in polls that matter to you! Polloroo makes it easy to engage, vote, and share your thoughts in just a few clicks. Your opinion has the power to influence change—so hop into Polloroo and get started today!
      </p>
    </div>
  )
}
