import logo from './logo.svg';
import './App.css';
import { PoliticalPoll } from './PoliticalPoll.tsx';
import { Header } from './components/Header.tsx';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { About } from './components/about/About.tsx';
import { Privacy } from './components/privacy/Privacy.tsx';

function App() {
  return (
    <BrowserRouter>
        <div className="h-full w-full">
          <header>
            <Header />
          </header>
          {/* <PoliticalPoll /> */}
          {/* <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Edit <code>src/App.js</code> and save to reload.
            </p>
            <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a>
          </header> */}
        </div>
      <Routes>
      <Route path='/' element={<PoliticalPoll />} />
        <Route path='/about' element={<About />} />
        <Route path='/privacy' element={<Privacy />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
